.about-section {
    padding: 65px;
    color: #ffffff;
    background-color: #1e1e1e; 
  }
  
  .about-content {
    display: flex;
    align-items: center; 
    font-size: large;
  }
  
  .about-image {
    width: 200px;
    height: 200;
    object-fit: cover;
    margin-right: 80px; 
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(63, 60, 60, 0.2);
  }
  
  .about-section p {
    text-align: left;
    margin: 10; 
  }
  
  @media (max-width: 600px) {
    .about-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  .header-bg {
    background-color: #f9f9f9;
    padding:25px;
    margin:0;
}
