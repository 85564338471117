/* Footer.css */

.footer {
  background-color: #f1f1f1;
  padding: 20px;
  text-align: center;
  /* Add any other styles you need */
}

body {
  margin: 0;             /* Reset margin */
  padding: 0;            /* Reset padding */
}

