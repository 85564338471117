body {
  font-family: 'Cormorant Garamond', serif;
  font-weight: 400;
}

.home-container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.hero-section {
  flex: 0 0 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #1a1a1a;
  color: #ffffff;
}

.main-title {
  font-size: clamp(2.2rem, 5vw, 4rem);
  margin: 0;
}

.subtitle {
  font-size: clamp(1.2rem, 3vw, 2rem);
  margin: 10px 0 0 0;
  animation: fadeIn 2s ease-in-out; 
}

.slide-container {
  position: relative;
  overflow: hidden; /* Added to contain the scaled image */

}

.slide-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Scale image to cover the container */
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.slide-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  /* Transparent initially */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
  opacity: 0;
}

.slide-container .caption {
  color: #fff;
  font-size: 2em;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.slide-container:hover img {
  /* Optional: Slight zoom effect on hover */
  transform: scale(1.00);
}

.slide-container:hover .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  /* Dark overlay on hover */
  opacity: 1;
}

.slide-container:hover .caption {
  opacity: 1;
}

.slideshow-section {
  width: 100%;
  max-width: 100vw; 
  background-color: #f1f1f1;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}




