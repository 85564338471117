
.header {
  background-color: #ffffff;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  height: 70px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo h1 {
  position: absolute;
  transform: translateY(-50%);
  font-size: 24px;
  margin: 0;
  font-weight: bold;
  color: #333333;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links a {
  margin: 0 15px;
  text-decoration: none;
  color: #333333;
  font-size: 18px;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #007bff;
}

/* Media query to hide the logo on small screens */
@media (max-width: 768px) {
  .logo {
    display: none; /* Hides the logo */
  }
}



