.social-icons {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    padding: 40px 0; 
    background-color: #f9f9f9;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px) scale(1);
    }
    to {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

.social-icons a {
    margin: 0 20px; 
    transition: transform 0.3s ease; 
    opacity: 0; 
    animation: fadeIn 1s forwards;
    transform: translateY(0) scale(1); 
}

.social-icons a:nth-child(1) {
    animation-delay: 0.3s;
}

.social-icons a:nth-child(2) {
    animation-delay: 0.6s;
}

.social-icons a:nth-child(3) {
    animation-delay: 0.9s;
}

.social-icons .icon {
    width: 50px; 
    height: 50px; 
    object-fit: contain; 
    border-radius: 50%; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.header-bg {
    background-color: #f9f9f9;
    padding:25px;
    margin:0;
}
